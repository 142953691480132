
import { Vue, Component } from 'vue-property-decorator';
import { TranslateResult } from "vue-i18n";
import IPhone from "@/components/IPhone.vue";
import appImage from '@/assets/images/polymind-app.jpg';

interface IFeature {
    image: string,
    title: string | TranslateResult,
    body: string | TranslateResult,
}

@Component({
    components: {
        IPhone,
    }
})
export default class Features extends Vue {
    panel = 0

    get images(): Array<string> {
        return this.features.map(feature => feature.image);
    }

    get features(): Array<IFeature> {
        return [
            { image: appImage, title: this.$i18n.t('home.features.incrementalLearning.title'), body: this.$i18n.t('home.features.incrementalLearning.body'), },
            { image: appImage, title: this.$i18n.t('home.features.dictionaries.title'), body: this.$i18n.t('home.features.dictionaries.body'), },
            { image: appImage, title: this.$i18n.t('home.features.listManagement.title'), body: this.$i18n.t('home.features.listManagement.body'), },
            { image: appImage, title: this.$i18n.t('home.features.automaticPlayback.title'), body: this.$i18n.t('home.features.automaticPlayback.body'), },
            { image: appImage, title: this.$i18n.t('home.features.multiPlatform.title'), body: this.$i18n.t('home.features.multiPlatform.body'), },
        ];
    }

    onIntersect(entries: Array<any>) {
        this.$root.$emit('toolbarDark', entries[0].isIntersecting)
    }
}
