var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-sheet',{staticClass:"fill-height pb-0 fake-player",attrs:{"color":"transparent","dark":""}},[_c('v-container',{staticClass:"d-flex flex-column fill-height pa-0 w-100"},[_c('v-row',{staticClass:"w-100 pt-1 px-2",staticStyle:{"flex":"0","opacity":"0.66"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-no-wrap",attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center"},[_c('img',{attrs:{"src":_vm.logo,"height":"20","width":"20"}}),_c('span',{class:{
                        'title text-none font-weight-light white--text': true,
                        'ml-2': !_vm.$vuetify.rtl,
                        'mr-2': _vm.$vuetify.rtl,
                    },staticStyle:{"font-size":"0.75rem !important"}},[_vm._v("Polymind")])])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"6"}}),_c('v-col',{staticClass:"text-no-wrap text-right text-uppercase",attrs:{"cols":"3"}},[_c('small',[_c('small',{domProps:{"textContent":_vm._s(_vm.languageFrom)}}),_vm._v(" / "),_c('small',{domProps:{"textContent":_vm._s(_vm.languageTo)}})])])],1),_c('v-row',{staticClass:"w-100 d-flex px-2",staticStyle:{"flex":"1"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-no-wrap",attrs:{"cols":"3"}}),_c('v-col',{staticClass:"text-center d-flex align-center justify-center",attrs:{"cols":"6"}},[(_vm.index >= 0)?_c('span',{class:{
                    'text-uppercase font-weight-bold word': true,
                    'primary--text text-h5 text-md-h4': _vm.showFront,
                    'text-h4 text-md-h3': _vm.showBack,
                },domProps:{"textContent":_vm._s(_vm.deck[_vm.index][_vm.showFront ? 0 : 1])}}):_vm._e()]),_c('v-col',{staticClass:"text-no-wrap text-right",attrs:{"cols":"3"}})],1),_c('v-row',{staticClass:"w-100 pb-1 px-2",staticStyle:{"flex":"0"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-no-wrap d-flex",staticStyle:{"gap":"0.5rem"},attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"disabled":"","small":""}},[_vm._v("mdi-pause")]),_c('v-icon',{attrs:{"disabled":"","small":""}},[_vm._v("mdi-volume-high")])],1),_c('v-col',{staticClass:"text-center d-flex justify-center align-center",staticStyle:{"gap":"0.5rem"},attrs:{"cols":"6"}},[_c('v-icon',{attrs:{"disabled":"","small":""}},[_vm._v("mdi-page-first")]),_c('small',[_c('small',[_vm._v(_vm._s(_vm.index + 1)+" of "+_vm._s(_vm.deck.length))])]),_c('v-icon',{attrs:{"disabled":"","small":""}},[_vm._v("mdi-page-last")])],1),_c('v-col',{staticClass:"text-no-wrap text-right",attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"disabled":"","small":""}},[_vm._v("mdi-fullscreen")])],1)],1),_vm._m(0)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-progress-linear',{attrs:{"id":"progress_bar","value":_vm.progress,"height":"3"}})
}]

export { render, staticRenderFns }