var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',_vm._g(_vm._b({directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}]},'v-app-bar',_vm.$attrs,false),_vm.$listeners),[_c('v-btn',{staticClass:"px-2",attrs:{"text":"","x-large":"","exact":"","to":{ name: 'home' }},on:{"click":() => _vm.goTo('#app')}},[_c('v-img',{attrs:{"src":_vm.logo,"width":"2.5rem"}}),_c('span',{class:{
            'text-h6 text-none font-weight-light': true,
            'primary--text': !_vm.dark,
            'white--text': _vm.dark,
            'ml-4': !_vm.$vuetify.rtl,
            'mr-4': _vm.$vuetify.rtl,
        }},[_vm._v("Polymind")])],1),(_vm.$vuetify.breakpoint.mdAndUp)?[_c('v-spacer'),_c('div',{staticClass:"text-right mr-12"},[_c('v-tabs',{attrs:{"background-color":"transparent","height":"80","slider-size":"3","dark":_vm.dark},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.panels),function(item,itemIdx){return _c('v-tab',{key:item,class:{
                        'd-none': itemIdx === 0
                    },domProps:{"textContent":_vm._s(_vm.$t('toolbar.' + item))},on:{"click":() => _vm.goTo('#' + item)}})}),1)],1)]:_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":!_vm.dark ? 'primary' : 'white',"rounded":"","large":_vm.$vuetify.breakpoint.mdAndUp,"href":_vm.clientUrl}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('toolbar.openClient'))}}),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }