
import {Component, Vue} from 'vue-property-decorator';
import walking from '@/assets/images/mobility/walking.jpg';
import driving from '@/assets/images/mobility/driving.jpg';
import hiking from '@/assets/images/mobility/hiking.jpg';
import relaxing from '@/assets/images/mobility/relaxing.jpg';
import sleeping from '@/assets/images/mobility/sleeping.jpg';
import working from '@/assets/images/mobility/working.jpg';

@Component
export default class Mobility extends Vue {
    situations = [
        { key: 'walking', thumbnail: walking },
        { key: 'driving', thumbnail: driving },
        { key: 'hiking', thumbnail: hiking },
        { key: 'relaxing', thumbnail: relaxing },
        { key: 'sleeping', thumbnail: sleeping },
        { key: 'working', thumbnail: working },
    ]
}
