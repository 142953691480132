
import { Vue, Component } from 'vue-property-decorator';
import IPhone from "@/components/IPhone.vue";
import language from '@/assets/images/schooling/language.jpg';
import geography from '@/assets/images/schooling/geography.jpg';
import anatomy from '@/assets/images/schooling/anatomy.jpg';
import biology from '@/assets/images/schooling/biology.jpg';
import history from '@/assets/images/schooling/history.jpg';

interface IDictionary {
    image: string,
}

@Component({
    components: {
        IPhone,
    }
})
export default class Dictionaries extends Vue {

    dictionaries: Array<IDictionary> = []

    created() {
        this.dictionaries.push(...[
            { image: language,  },
            { image: geography, },
            { image: anatomy, },
            { image: biology, },
            { image: history, },
            { image: language, },
            { image: geography, },
            { image: anatomy, },
            { image: biology, },
            { image: history, },
            { image: language, },
            { image: geography, },
            { image: anatomy, },
            { image: biology, },
            { image: history, },
        ])
    }
}
