
import {Vue, Component, VModel, Prop} from 'vue-property-decorator';

@Component
export default class IPhone extends Vue {

    @VModel({
        default: () => ([])
    }) images!: string | Array<string>

    @Prop({
        type: Boolean,
        default: false
    }) includeBorder!: boolean

    @Prop({
        type: Number,
        default: 0
    }) index!: number

    get formattedImages(): Array<string> {
        return typeof this.images === 'string'
            ? [this.images]
            : this.images;
    }
}
