
import {Vue, Component, VModel, Prop} from 'vue-property-decorator';
import logo from '@/assets/images/polymind-light.svg'

@Component
export default class FakePlayer extends Vue {

    @VModel() deck!: Array<Array<string>>
    @Prop() languageFrom!: string
    @Prop() languageTo!: string

    logo = logo
    index = -1
    progress = 0
    delay = 5000
    startTime: Date = new Date()
    endTime: Date = new Date()
    showBack = false
    showFront = true
    repeat = 0

    get isOtherSide() {
        return this.showBack;
    }

    get minIndex() {
        return 0;
    }

    get maxIndex() {
        return this.deck.length - 1;
    }

    onFrame(progressBarElement: HTMLElement) {
        const nowTime = new Date().getTime();
        const remainingTime = this.endTime.getTime() - nowTime;
        const originalRange = this.endTime.getTime() - this.startTime.getTime();
        if (remainingTime <= 0) {
            this.next();
            this.setFirstSide(true);
            this.setOtherSide(false);
        } else {
            this.progress = (this.delay - remainingTime) * 100 / this.delay;
            if (progressBarElement) {
                progressBarElement.style.width = this.progress + '%';
            }

            const midProgress = originalRange / 2;
            if (!this.isOtherSide && remainingTime < midProgress) {
                this.setFirstSide(false);
                this.setOtherSide(true);
            }
        }

        requestAnimationFrame(() => this.onFrame(progressBarElement));
    }

    resetTime(date = new Date()) {
        this.startTime = date;
        this.endTime = new Date(date.getTime() + this.delay);
        this.progress = 0;

        const progressBarElement = document.getElementById('progress_bar');
        if (progressBarElement) {
            const element = progressBarElement.querySelector('.v-progress-linear__determinate');
            if (element) {
                // @ts-ignore
                element.style.width = this.progress + '%';
            }
        }
    }

    next() {
        this.repeat = 0;
        this.index++;
        if (this.index > this.maxIndex) {
            this.index = this.minIndex;
        }

        this.resetTime();
    }

    play() {
        const progressBarElement = document.getElementById('progress_bar');
        if (progressBarElement) {
            const element = progressBarElement.querySelector('.v-progress-linear__determinate');
            if (element) {
                // @ts-ignore
                requestAnimationFrame(() => this.onFrame(element));
            }
        }
    }

    setFirstSide(visible: boolean) {
        this.showFront = visible;
        this.showBack = !visible;
    }

    setOtherSide(visible: boolean) {
        this.showBack = visible;
        this.showFront = !visible;
    }

    mounted() {
        this.play();
    }
}
