
import Viewport from '@/components/layout/Viewport.vue'
import Hero from '@/views/Home/Hero.vue';
import HowItWorks from '@/views/Home/HowItWorks.vue';
import Features from '@/views/Home/Features.vue';
import Dictionaries from '@/views/Home/Dictionaries.vue';
import Mobility from '@/views/Home/Mobility.vue';
import Schooling from '@/views/Home/Schooling.vue';
import CallToAction from '@/views/Home/CallToAction.vue';
import Footer from "@/components/layout/Footer.vue";
import {Component, Vue} from 'vue-property-decorator';
import backgroundImg from '@/assets/images/background.png';

@Component({
    components: {
        Viewport,
        Hero,
        HowItWorks,
        Features,
        Dictionaries,
        Mobility,
        Schooling,
        CallToAction,
        Footer
    },
})
export default class Home extends Vue {
    backgroundImg = backgroundImg
}
